import en from './locales/en-EN.json';
import es from './locales/es-ES.json';

export default defineI18nConfig(() => ({
  legacy: false,
  locales: ['en', 'es'],
  locale: 'es',
  messages: {
    en: { ...en },
    es: { ...es }
  },
  lazy: true,
  strategy: 'prefix_and_default'
}));